/* Global variables */

:root {
  /**
   * Compute root scrollbar width/height and "true" 100vw/100vh sizes. Note that
   * the root-scrollbar-width/height are computed dynamically in
   * useRootScrollbarSizes hook.
   */
  --g--root-scrollbar-width: 0px;
  --g--root-scrollbar-height: 0px;
  --g--100vw: calc(100vw - var(--g--root-scrollbar-width));
  --g--100vh: calc(100vh - var(--g--root-scrollbar-height));

  /* Fonts (the specific font variables are defined in _app.tsx) */
  --g--font-fallback: arial, helvetica, sans-serif;
  --g--font-primary: var(--g--font-suisse-intl, var(--g--font-fallback));
  --g--font-secondary: var(--g--font-suisse-intl-mono, var(--g--font-fallback));

  /* Color palette - RGB */

  /* Basic colors */
  --g--color-ray-orange: #ff6548;
  --g--color-white: #fff;
  --g--color-ray-sky: #489cff;
  --g--color-jet-black: #000;
  --g--color-black: #150011;
  --g--color-charcoal: #222;
  --g--color-medium-grey: #4b5557;
  --g--color-grey: #bac7c6;
  --g--color-light-grey: #d7e1e0;
  --g--color-live-red: #f33;
  --g--color-ocean-blue: #32b9b9;

  /* Glass colors */
  --g--glass-light-0: rgb(255 255 255 / 0%);
  --g--glass-light-100: rgb(255 255 255 / 32%);
  --g--glass-light-500: rgb(255 255 255 / 48%);
  --g--glass-light-900: rgb(255 255 255 / 64%);
  --g--glass-dark-0: rgb(0 0 0 / 0%);
  --g--glass-dark-100: rgb(0 0 0 / 30%);
  --g--glass-dark-500: rgb(0 0 0 / 40%);
  --g--glass-dark-900: rgb(0 0 0 / 50%);

  /* Margins */
  --g--mx-md: 24px;
  --g--mx-sm: 12px;
  --g--mx-lg: 48px;
  --g--mx-viewport: 52px;
  --g--my-md: 24px;
  --g--my-sm: 12px;
  --g--my-lg: 48px;
  --g--my-viewport: 72px;

  /* Border radii */
  --g--border-radius-sm: 6px;
  --g--border-radius-md: 12px;

  /* Buttons */
  --g--button-border-radius: 6px;
  --g--button-anim-duration: 0.15s;
  --g--button-anim-easing: ease-out;

  /* Shadows & highlights */
  --g--shadow-sm: 0px 2px 8px 0px rgb(0 0 0 / 35%);
  --g--shadow-md: 0px 4px 16px 0px rgb(0 0 0 / 35%);
  --g--shadow-xl: 0px 8px 24px 0px rgb(0 0 0 / 70%);
  --g--highlight-sm: 0px -1px 0px 0px rgb(255 255 255 / 20%);
  --g--highlight-md: 0px -1px 0px 0px rgb(255 255 255 / 40%);
  --g--inset-highlight-sm: inset 0px 1px 0px 0px rgb(255 255 255 / 20%);
  --g--inset-highlight-md: inset 0px 1px 0px 0px rgb(255 255 255 / 40%);

  /* Animations */
  --g--anim-duration: 0.2s;
  --g--anim-easing: ease-out;
  --g--anim-bounce-easing: cubic-bezier(0.33, 0.975, 0, 1.65);

  /* Cards */
  --g--card-extended-edge: 16px;
  --g--card-gap: 16px;
  --g--card-action-gap: 6px;
  --g--card-actions-top-margin: 12px;
  --g--card-action-height: 34px;
  --g--card-border-radius: 8px;
  --g--card-count: 1;
  --g--card-gap-count: calc(var(--g--card-count) - 1);
  --g--card-gapless-area: calc(
    var(--g--100vw) - calc(var(--g--mx-viewport) * 2) -
      calc(var(--g--card-gap) * var(--g--card-gap-count))
  );
  --g--card-width: calc(var(--g--card-gapless-area) / var(--g--card-count));
  --g--card-height: calc(var(--g--card-width) / (16 / 9));
  --g--card-width-unitless: 1; /* Computed with JS */

  /*
  We try to keep the card width at exactly 220px at the beginning of every
  breakpoint. This means that the card count will change at 244px interval
  (assuming that the paddings and gaps stay the same).
  */
  @media (min-width: 620px) {
    & {
      --g--card-count: 2;
    }
  }

  @media (min-width: 864px) {
    & {
      --g--card-count: 3;
    }
  }

  @media (min-width: 1108px) {
    & {
      --g--card-count: 4;
    }
  }

  @media (min-width: 1352px) {
    & {
      --g--card-count: 5;
    }
  }

  @media (min-width: 1596px) {
    & {
      --g--card-count: 6;
    }
  }

  @media (min-width: 1840px) {
    & {
      --g--card-count: 7;
    }
  }

  /* The 8 and 9 card setups are aimed for ultrawide screens and beyond. */
  @media (min-width: 2084px) and (min-aspect-ratio: 2) {
    & {
      --g--card-count: 8;
    }
  }

  @media (min-width: 2328px) and (min-aspect-ratio: 3) {
    & {
      --g--card-count: 9;
    }
  }
}

/* Style overrides for all elements */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Root element styles */

html {
  user-select: none;

  overflow: hidden;
  overscroll-behavior: none;
  display: none;

  height: 100%;

  font-size: 62.5%;
  color: var(--g--color-white);

  background-color: var(--g--color-jet-black);

  &.isRay {
    display: block;
  }
}

body {
  position: relative;

  overflow: hidden;
  overscroll-behavior: none;

  width: 100%;
  height: 100%;
  margin: 0;

  font-family: var(--g--font-primary);
  font-size: 1.8rem;
  line-height: 1.5;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  scroll-behavior: smooth;

  position: relative;

  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;

  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background: var(--g--color-black);
    box-shadow: inset 1px 0 4px var(--g--glass-dark-100);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--g--glass-light-100);
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--g--glass-light-900);
  }
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/1ed5590bc390621f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/98e01a550dd67bfd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/6fa1afdfb81c9ae2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/114d0b1e2fb44d56-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/50918917bb137e4d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/8c3afd4c64537d1c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/a3512529a0e86962-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/b1c153452a79a6bb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}@font-face {font-family: '__suisseIntl_Fallback_4f0dd8';src: local("Arial");ascent-override: 95.68%;descent-override: 30.18%;line-gap-override: 0.00%;size-adjust: 103.05%
}.__className_4f0dd8 {font-family: '__suisseIntl_4f0dd8', '__suisseIntl_Fallback_4f0dd8'
}

@font-face {
font-family: '__suisseIntlMono_a44523';
src: url(/_next/static/media/4c017ab9f28baa7b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__suisseIntlMono_Fallback_a44523';src: local("Arial");ascent-override: 69.65%;descent-override: 21.97%;line-gap-override: 0.00%;size-adjust: 141.57%
}.__className_a44523 {font-family: '__suisseIntlMono_a44523', '__suisseIntlMono_Fallback_a44523';font-weight: 400;font-style: normal
}

